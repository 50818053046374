"use client";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getLoggedInUser } from "@/lib/user";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { signOut } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Fragment, useEffect, useRef, useState } from 'react';
import { FaBars } from "react-icons/fa6";
import { FaChevronDown } from 'react-icons/fa';

// import NotificationsDropdown from "./notifications-dropdown";
const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'About Us', href: '/about-us', current: false },
  { name: 'Our Courses', href: '/our-courses', current: false },
  {
    name: 'Resources',
    // current: false,
    children: [
      { name: 'Blogs', href: '/blog' },
      // { name: 'Media', href: '/media' }
    ]
  },
  // { name: 'Blogs', href: '/blog', current: false },
  { name: 'Contact Us', href: '/contact-us', current: false },
  // { name: 'Media', href: '/media', current: false },
  // { name: 'Login', href: '/login', current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Header(props: any) {
  const { session } = props
  // console.log("🚀 ~ Header ~ session:", session)
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const pathname = usePathname();
  const [userDetails, setUserDetails] = useState<any>('')
  const [toggleMenu, setToggleMenu] = useState<any>(false)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getLoggedInUser();
        setUserDetails(user?.data)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };

    const handleScroll = () => {
      setToggleMenu(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dropdownRef]);
  
  const handleItemClick = (event: any) => {
    // alert("Item clicked");

    event.stopPropagation();
  };
  return (
    <Disclosure as="nav" className="bg-[#FFFFFF] shadow-lg fixed top-0 w-full z-50" onClick={handleItemClick}>
      {({ open }) => (
        <>
          <div className="mx-auto container px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FaBars className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <FaBars className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-shrink-0 items-center hidden sm:visible sm:block">
                <Link href="/" className="inline-block">
                  <Image
                    height={218} width={356}
                    className="h-[50px] w-auto pt-[4px]"
                    src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/exc_logo.webp"
                    alt="ExC Academy"
                    blurDataURL="URL"
                    placeholder="blur"
                  />
                </Link>
              </div>
              <div className="flex flex-1 items-center sm:justify-end justify-start">
                <div className="visible sm:hidden flex flex-shrink-0 items-center">
                  <Link href="/" className="inline-block">
                    <Image
                      height={218} width={356}
                      className="h-[50px] w-auto pt-[4px] pl-[8px]"
                      src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/exc_logo.webp"
                      alt="ExC Academy"
                      blurDataURL="URL"
                      placeholder="blur"
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-10 sm:block">
                  <div className="flex md:space-x-4 items-center">
                    {navigation.map((item) => (
                      <div key={item.name} className="relative">
                        {item.children ? (
                          <>
                            <button
                              className={classNames(
                                'text-sm font-semibold flex items-center',
                                (pathname === item.href || item.children.some(child => pathname === child.href)) ? 'text-[#9014dc] font-bold mt-[3px]' : 'text-[#302A33] hover:font-semibold hover:text-[#9014dc] mt-[3px]'
                              )}
                              aria-current={pathname === item.href ? 'page' : undefined}

                              onClick={() => setToggleMenu(!toggleMenu)}
                            >
                              {item.name}
                              <FaChevronDown className="ml-1 text-[12px]" />
                            </button>
                            {toggleMenu ? <div ref={dropdownRef} className="absolute mt-2 w-30 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                              {item.children.map((child) => (
                                <Link
                                  key={child.name}
                                  href={child.href}

                                  onClick={() => setToggleMenu(false)}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  hover:text-[#9014dc]"
                                >
                                  {child.name}
                                </Link>
                              ))}
                            </div> : null}
                          </>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            onClick={() => setToggleMenu(false)}
                            className={classNames(
                              'px-3 py-2 text-sm font-semibold',
                              pathname === item.href ? 'text-[#9014dc] font-bold' : 'text-[#302A33] hover:font-semibold hover:text-[#9014dc]'
                            )}
                            aria-current={pathname === item.href ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        )}
                      </div>
                    ))}
                    {session == undefined && <Link
                      href='/login'
                      className='hover:bg-gray-800 bg-black font-semibold text-white uppercase px-3 py-2 text-sm lg:inline-block md:inline-block block'>
                      Sign In
                    </Link>}
                  </div>
                </div>
              </div>
              {(session != undefined || session != null) &&
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-0 sm:pr-0">
                  {/* <NotificationsDropdown /> */}
                  <div className="flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <FaBars className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <FaBars className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="overflow-hidden relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        {/* <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                        <Avatar>
                          {userDetails?.profileImag ? <AvatarImage src={userDetails?.profileImag ? userDetails?.profileImag : session?.user?.user?.image} alt={session?.user?.user?.name} />
                            :

                            <AvatarFallback className="AvatarFallback" delayMs={600}>
                              {/* {session?.user?.user?.name?.substring(0, 1)} */}
                              {userDetails?.firstName?.substring(0, 1)}
                            </AvatarFallback>}
                        </Avatar>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="overflow-hidden absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/user-account/profile"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              My Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/user-account/course-workshop"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              My Certificate 
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/user-account/settings"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              // href="/login"
                              onClick={() => signOut(
                                { callbackUrl: '/' }
                              )}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Sign out
                            </p>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-0 px-0 pt-2">
              {navigation.map((item, index) => (
                <Fragment key={`${index}+keys`}>
                  {item.children ? (
                    <>

                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          (pathname === item.href || item.children.some(child => pathname === child.href)) ? 'bg-gray-900 text-white' : 'text-gray-700 hover:bg-gray-700 hover:text-white',
                          'block px-6 py-3 text-base font-medium mt-0'
                        )}
                        aria-current={pathname === item.href ? 'page' : undefined}

                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default behavior
                          setToggleMenu(!toggleMenu);
                        }}
                      >
                        {item.name}

                      </Disclosure.Button>
                      {toggleMenu ? <div className="absolute w-full origin-top-right rounded-none bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                        {item.children.map((child) => (
                          <Disclosure.Button
                          key={child.name}
                          as="a"
                          href={child.href}
                          className={classNames(
                            pathname === child.href ? 'bg-gray-900 text-white' : 'text-gray-700 hover:bg-gray-700 hover:text-white',
                            'block px-6 py-3 text-base font-small mt-0'
                          )}
                          aria-current={pathname === child.href ? 'page' : undefined}
                        >
                          {child.name}
                        </Disclosure.Button>
                        ))}
                      </div> : null}
                      <div className="border border-color-[#ddd] my-0 mx-auto w-[92%]"></div>
                    </>
                  ) : (
                    <div key={index}>
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          pathname === item.href ? 'bg-gray-900 text-white' : 'text-gray-700 hover:bg-gray-700 hover:text-white',
                          'block px-6 py-3 text-base font-medium mt-0'
                        )}
                        aria-current={pathname === item.href ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                      <div className="border border-color-[#ddd] my-0 mx-auto w-[92%]"></div>
                    </div>
                  )}
                </Fragment>

              ))}
              {session == undefined && <Link
                href='/login'
                className='text-gray-700 hover:bg-gray-700 hover:text-white
                      block px-6 py-3 text-base font-medium mt-0'>
                Sign In
              </Link>}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
